import './index.css'
import './favicon.ico'
import * as THREE from 'three'
import gsap from 'gsap'
import galaxyVertexShader from './shaders/galaxy/vertex.glsl'
import galaxyFragmentShader from './shaders/galaxy/fragment.glsl'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

import sunVertexShader from './shaders/sunPerlin/vertex.glsl'
import sunFragmentShader from './shaders/sunPerlin/fragment.glsl'
import sunVertexTexture from './shaders/sunTexture/vertex.glsl'
import sunFragmentTexture from './shaders/sunTexture/fragment.glsl'


//Galaxy Parameters
const parameters = {}
parameters.count = 200000
parameters.size = 30
parameters.radius = 5
parameters.branches = 3
parameters.spin = 1
parameters.randomness = 0.5
parameters.randomnessPower = 3
parameters.insideColor = '#ff6030'
parameters.outsideColor = '#1b3984'

let geometry = null
let material = null
let galaxy = null

const generateGalaxy = () =>
{
    if(galaxy !== null)
    {
        geometry.dispose()
        material.dispose()
        scene.remove(galaxy)
    }

    /**
     * Geometry
     */
    geometry = new THREE.BufferGeometry()

    const positions = new Float32Array(parameters.count * 3)
    const colors = new Float32Array(parameters.count * 3)
    const scales = new Float32Array(parameters.count * 1)
    const randomness = new Float32Array(parameters.count * 3)

    const insideColor = new THREE.Color(parameters.insideColor)
    const outsideColor = new THREE.Color(parameters.outsideColor)

    for(let i = 0; i < parameters.count; i++)
    {
        const i3 = i * 3

        // Position
        const radius = Math.random() * parameters.radius

        const branchAngle = (i % parameters.branches) / parameters.branches * Math.PI * 2

        const randomX = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius
        const randomY = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius
        const randomZ = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius

        positions[i3    ] = Math.cos(branchAngle) * radius + randomX
        positions[i3 + 1] = randomY
        positions[i3 + 2] = Math.sin(branchAngle) * radius + randomZ

        randomness[i3] = randomX
        randomness[i3+1]= randomY
        randomness[i3+2]=randomZ

        // Color
        const mixedColor = insideColor.clone()
        mixedColor.lerp(outsideColor, radius / parameters.radius)

        colors[i3    ] = mixedColor.r
        colors[i3 + 1] = mixedColor.g
        colors[i3 + 2] = mixedColor.b
    }

    for (let i=0;i<parameters.count;i++){
        //Scales
        scales[i] = Math.random()
    }      
    
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
    geometry.setAttribute('aScale',new THREE.BufferAttribute(scales,1))
    geometry.setAttribute('aRandomness',new THREE.BufferAttribute(randomness,3))


    /**
     * Material
     */

    material = new THREE.ShaderMaterial({
        depthWrite:false,
        blending:THREE.AdditiveBlending,
        vertexColors:true,
        vertexShader: galaxyVertexShader,
        fragmentShader: galaxyFragmentShader,
        uniforms:{
            uSize:{value:30 * renderer.getPixelRatio()},
            uTime:{value:0}

        }
    })
    parameters.size = material.uniforms.uSize.value
    
    /**
     * Points
     */
    galaxy = new THREE.Points(geometry, material)
    scene.add(galaxy)
    
}


/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color(0.0,0.0,0.0,0.0)

/**
 * Sizes
 */
 const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}


/**
 * Renderer
 */
 const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha:true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


//Background Particles

// Material
const particlesMaterial = new THREE.PointsMaterial({
    size: 0.005,

})

// Geometry
const particlesGeometry = new THREE.BufferGeometry()
const count = 500

const positions = new Float32Array(count * 3) 

for(let i = 0; i < count ; i++) 
{

    positions[i * 3 + 0] = (Math.random() -0.5) * 22.0
    positions[i * 3 + 1] = (Math.random() - 0.5) * 15.0 -11.0
    positions[i * 3 + 2] = (Math.random() + 0.5) * 10.0 -8.0
}

particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3)) 
// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)



//Window Object Positioning
const objectsDistance = 4

//Loaders
const loadingBarElement = document.querySelector('.loading-bar')
const loadingManager = new THREE.LoadingManager()

loadingManager.onStart = function(){

    //console.log('Loading Started!')

}
loadingManager.onProgress = function(itemsUrl,itemsLoaded,itemsTotal){

    const progressRatio = itemsLoaded / itemsTotal
    loadingBarElement.style.transform = `scaleX(${progressRatio})`

}

loadingManager.onLoad = function ( ) {

	//console.log( 'Loading complete!');
    
    loadingBarElement.classList.add('ended')
    loadingBarElement.style.transform = ''
    
} 

//Model Loaders
const gltfloader = new GLTFLoader(loadingManager)
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco')
gltfloader.setDRACOLoader(dracoLoader)


//Promisfy single model load
function loadModel(url){
      return new Promise(resolve =>{
        gltfloader.load(url,resolve)
    })
}

//Initiate models
let moonModel =null

const p1 = loadModel('/models/moon/Moon.glb').then(result=>{moonModel = result.scene.children[0]})

//Promisfy multiple model load
Promise.all([p1]).then( ()=>{

    moonModel.scale.set(0.0025,0.0025,0.0025)
    moonModel.rotation.set(1.7,0.0,0.0)
    moonModel.position.x = 2
    moonModel.position.y = -objectsDistance * 2.0
    scene.add(moonModel)

    
}).then(()=>{


}).then(()=>{
    animateScene()
})

//Scroll 
let scrollY = window.scrollY

let currentSection = 0
window.addEventListener('scroll', () =>
{   
  
    scrollY = window.scrollY
    //console.log(scrollY)
    const newSection = Math.round(scrollY / sizes.height)
    
    if(newSection != currentSection){
        currentSection = newSection
        if(currentSection == 2){
            gsap.to(directionalLight.position,{duration:5,ease:'power2.inOut',x:'-2',y:'1',z:'0.2'})
        }  
        else{
            gsap.to(directionalLight.position,{duration:5,ease:'power2.inOut',x:'2',y:'2',z:'0'})

        } 
        if(currentSection ==1){
            gsap.to(texturedSun.scale,{duration:5,ease:'power2.inOut',x:0.21,y:0.21,z:0.21})
        }
        else{
            gsap.to(texturedSun.scale,{duration:5,ease:'power2.inOut',x:0.15,y:0.15,z:0.15})
        }
               
    }   
})




//Cursor
const cursor = {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event) =>
{
    cursor.x = event.clientX / sizes.width
    cursor.y = event.clientY / sizes.height
    

})

/**
 * Lights
 */
 const directionalLight = new THREE.DirectionalLight('#ffffff', 4)
 directionalLight.position.set(2, 2, -2)
 scene.add(directionalLight)

 const ambientLight = new THREE.AmbientLight(0x404040,2)
 scene.add(ambientLight)


//Window Resize 
window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})



//Initiate Galaxy
generateGalaxy()




// Geometries
const sunTextureGeometry = new THREE.SphereGeometry(5.0,32,32) //sunTexture
const perlinGeo = new THREE.SphereGeometry(5.0,32,32) //sunPerlin

// Material Texture CubeRender Output
const materialSun = new THREE.ShaderMaterial({
    vertexShader: sunVertexTexture,
    fragmentShader: sunFragmentTexture,
    side:THREE.DoubleSide,
    uniforms:{
        uTime:{value:0},
        uPerlin:{value:null}
    }
})

// Mesh
const texturedSun = new THREE.Mesh(sunTextureGeometry, materialSun)
scene.add(texturedSun)

texturedSun.position.x = -2;
texturedSun.position.y = -objectsDistance * 1
texturedSun.scale.set(0.15,0.15,0.15)

//Initialize new scene for rendered target
const scene2 = new THREE.Scene()
scene2.background = new THREE.Color(1.0,1.0,1.0)

// Material Perlin Noise Calculation with fBM
const materialPerlin = new THREE.ShaderMaterial({
    vertexShader: sunVertexShader,
    fragmentShader: sunFragmentShader,
    side:THREE.DoubleSide,
    uniforms:{
        uTime:{value:0}
    }
})

const perlinSun = new THREE.Mesh(perlinGeo,materialPerlin)
scene2.add(perlinSun)

/* perlinSun.position.x = -1.8;
perlinSun.position.y = -objectsDistance * 1
perlinSun.scale.set(0.8,0.8,0.8) */



//CubeCamera

const cubeRenderTarget = new THREE.WebGLCubeRenderTarget( 256, {
    format: THREE.RGBFormat,
    generateMipmaps: true,
    minFilter: THREE.LinearMipmapLinearFilter,
    encoding: THREE.sRGBEncoding // temporary -- to prevent the material's shader from recompiling every frame
} )
const cubeCamera = new THREE.CubeCamera( 0.1, 100, cubeRenderTarget );


//Parallalx Camera Group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

//Add camera to group
// Base camera
const camera = new THREE.PerspectiveCamera(40, sizes.width / sizes.height, 0.1, 100)
const camera2 = new THREE.PerspectiveCamera(40, sizes.width / sizes.height, 0.1, 100)
camera2.position.z = 6
camera.position.z = 6

cameraGroup.add(camera)

//Audio

    document.querySelector('.button').addEventListener('click', initAudio) 
    const listener = new THREE.AudioListener()
    camera.add(listener)   
    const sound = new THREE.Audio(listener)
    sound.isPlaying=false


let elementPlay = null

function initAudio(){


    if(!sound.isPlaying){
        // load a sound and set it as the Audio object's buffer
        const audioLoader = new THREE.AudioLoader()
        audioLoader.load( 'sounds/Hans-Zimmer-STAY-v2.mp3', function( buffer ) {
            sound.setBuffer( buffer )
            sound.setLoop( true )
            sound.setVolume(0.3)
            sound.play()
            sound.isPlaying =true

            elementPlay = document.getElementsByClassName("far fa-play-circle")
            elementPlay[0].classList.add('fas','fa-pause')
            elementPlay[0].classList.remove('far','fa-play-circle')
            
        })  
    }else{
        sound.stop()
        sound.isPlaying =false

        elementPlay = document.getElementsByClassName("fas fa-pause")
        elementPlay[0].classList.add('far','fa-play-circle')
        elementPlay[0].classList.remove('fas','fa-pause')
    }
    
}


/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const animateScene = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    //Animate Galaxy
    material.uniforms.uTime.value = elapsedTime

    //Animate Moon
    moonModel.rotation.y = elapsedTime *0.05
    moonModel.rotation.x = elapsedTime * 0.02
     
    //Render Perlin Noise for Sun
    cubeCamera.update( renderer, scene2 ) //Cube Camera renders the scene2 where perlin computation is and registers the texture
	texturedSun.material.uniforms.uPerlin.value = cubeRenderTarget.texture //Assigns target texture to value
    

    //Animate Perlin Noise
    perlinSun.material.uniforms.uTime.value = elapsedTime
    texturedSun.material.uniforms.uTime.value = elapsedTime

    //Animate Camera
    camera.position.y = -scrollY / sizes.height * objectsDistance

    const parallaxX = cursor.x * 0.5
    const parallaxY = -cursor.y * 0.5

    cameraGroup.position.x += (parallaxX -cameraGroup.position.x) * deltaTime * 5 //lerp
    cameraGroup.position.y += (parallaxY -cameraGroup.position.y) * deltaTime * 5

    //resize control
    if(sizes.width <960){
        moonModel.position.x = 0
        texturedSun.position.x = 0

    }else{
        moonModel.position.x =2
        texturedSun.position.x= -2
    }

    // Render
    renderer.render(scene, camera)

    // Call animateScene again on the next frame
    window.requestAnimationFrame(animateScene)
}

